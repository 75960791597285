<template>
  <TTDataTable
    :headers="headers"
    :items="tests"
    :loading="loading"
    disable-pagination
    disable-sort
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Тесты</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.skillId="{ item }">
      <div class="ma-2">
        <div class="mb-1">
          <TestTableSkillName :skill-id="item.skillId" />
        </div>

        <div>
          <VChip small>
            {{ item.skillId }}
          </VChip>
        </div>
      </div>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </TTDataTable>
</template>

<script>
import TestTableSkillName from './TestTableSkillName.vue';

export default {
  name: 'TestsTable',
  components: {
    TestTableSkillName,
  },
  props: {
    tests: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Описание',
          align: 'left',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Навык',
          align: 'left',
          sortable: true,
          value: 'skillId',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
