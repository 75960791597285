var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VRow',{attrs:{"justify":"space-between"}},[_c('VCol',{attrs:{"cols":"10"}},[_c('div',{staticClass:"tt-text-title-2 tt-light-mono-100--text py-3"},[_vm._v(" Тесты ")])]),_c('VCol',[_c('TTBtn',{attrs:{"to":{ name : _vm.Names.R_MULTI_LEVEL_TEST_TESTS_CREATE }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Новый тест ")],1)],1)],1),_c('TestsTable',{attrs:{"tests":_vm.tests,"loading":_vm.loading},scopedSlots:_vm._u([{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('TTTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('TTBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"tt-light-blue vibrant","to":{
              name : _vm.Names.R_MULTI_LEVEL_TEST_TESTS_VIEW,
              params : {
                testId : item.id
              }
            }}},'TTBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('TTTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('TTBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"tt-light-blue vibrant","to":{
              name : _vm.Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX,
              params : {
                testId : item.id
              }
            }}},'TTBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-list ")])],1)]}}],null,true)},[_c('span',[_vm._v("Топики")])]),_c('TTTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('TTBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"tt-light-orange vibrant","to":{
              name : _vm.Names.R_MULTI_LEVEL_TEST_TESTS_EDIT,
              params : {
                testId : item.id
              }
            }}},'TTBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('TTTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('TTBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"tt-light-red vibrant"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteTest(item)}}},'TTBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}])}),_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить тест ")]),_c('VCardText',[_vm._v(" Тест "),_c('span',{staticClass:"tt-light-red--text"},[_vm._v(_vm._s(_vm.test.name))]),_vm._v(" будет удален! "),_c('br'),_vm._v(" Действительно хотите удалить тест? ")]),_c('VCardActions',[_c('VSpacer'),_c('TTBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('TTBtn',{attrs:{"color":"tt-light-red vibrant","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1),(_vm.contentItemsPagesTotal > 0)?_c('VPagination',{attrs:{"total-visible":"10","length":_vm.contentItemsPagesTotal},model:{value:(_vm.contentItems.page),callback:function ($$v) {_vm.$set(_vm.contentItems, "page", $$v)},expression:"contentItems.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }