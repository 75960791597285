<template>
  <div>
    <VRow justify="space-between">
      <VCol cols="10">
        <div class="tt-text-title-2 tt-light-mono-100--text py-3">
          Тесты
        </div>
      </VCol>
      <VCol>
        <TTBtn :to="{ name : Names.R_MULTI_LEVEL_TEST_TESTS_CREATE }">
          <VIcon left>
            fal fa-plus
          </VIcon>

          Новый тест
        </TTBtn>
      </VCol>
    </VRow>
    <TestsTable
      :tests="tests"
      :loading="loading"
    >
      <template #rowActions="{ item }">
        <TTTooltip left>
          <template #activator="{ on, attrs }">
            <TTBtn
              icon
              small
              color="tt-light-blue vibrant"
              class="mx-1"
              :to="{
                name : Names.R_MULTI_LEVEL_TEST_TESTS_VIEW,
                params : {
                  testId : item.id
                }
              }"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon small>
                fal fa-eye
              </VIcon>
            </TTBtn>
          </template>

          <span>Просмотр</span>
        </TTTooltip>

        <TTTooltip left>
          <template #activator="{ on, attrs }">
            <TTBtn
              icon
              small
              color="tt-light-blue vibrant"
              class="mx-1"
              :to="{
                name : Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX,
                params : {
                  testId : item.id
                }
              }"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon small>
                fal fa-list
              </VIcon>
            </TTBtn>
          </template>

          <span>Топики</span>
        </TTTooltip>

        <TTTooltip left>
          <template #activator="{ on, attrs }">
            <TTBtn
              icon
              small
              color="tt-light-orange vibrant"
              class="mx-1"
              :to="{
                name : Names.R_MULTI_LEVEL_TEST_TESTS_EDIT,
                params : {
                  testId : item.id
                }
              }"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon small>
                fal fa-edit
              </VIcon>
            </TTBtn>
          </template>

          <span>Редактирование</span>
        </TTTooltip>

        <TTTooltip left>
          <template #activator="{ on, attrs }">
            <TTBtn
              icon
              small
              color="tt-light-red vibrant"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click.stop="deleteTest(item)"
            >
              <VIcon small>
                fal fa-trash
              </VIcon>
            </TTBtn>
          </template>

          <span>Удаление</span>
        </TTTooltip>
      </template>
    </TestsTable>
    <VDialog
      v-model="confirmDialog"
      max-width="500px"
      @click:outside="handleCancel"
    >
      <VCard>
        <VCardTitle class="headline">
          Удалить тест
        </VCardTitle>

        <VCardText>
          Тест <span class="tt-light-red--text">{{ test.name }}</span> будет удален! <br>
          Действительно хотите удалить тест?
        </VCardText>

        <VCardActions>
          <VSpacer />
          <TTBtn
            text
            @click="handleCancel"
          >
            Нет
          </TTBtn>

          <TTBtn
            color="tt-light-red vibrant"
            text
            @click="handleDelete"
          >
            Да
          </TTBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <VPagination
      v-if="contentItemsPagesTotal > 0"
      v-model="contentItems.page"
      total-visible="10"
      :length="contentItemsPagesTotal"
    />
  </div>
</template>

<script>
import TestsTable from './TestsTable.vue';

export default {
  name: 'TestsTableContainer',

  components: {
    TestsTable,
  },

  inject: ['Names'],

  data() {
    return {
      tests: [],
      loading: false,
      test: {},
      confirmDialog: false,
      contentItems: {
        page: 1,
        limit: 20,
        total: 0,
      },
    };
  },
  computed: {
    contentItemsPagesTotal() {
      return Math.ceil(this.contentItems.total / this.contentItems.limit);
    },
  },
  watch: {
    'contentItems.page': {
      async handler(pageNumber) {
        await this.fetch(pageNumber, this.contentItems.limit);
      },
      immediate: true,
    },
  },
  methods: {
    async fetch(page = 1, limit = this.contentItems.limit) {
      try {
        this.loading = true;
        const data = { page, limit };

        const {
          tests = [],
          pagination: { total = 0 },
        } = await this.$di.api.MultiLevelTest.TestsIndex({ data });

        this.contentItems.total = total;
        this.tests = tests;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.test;
        const data = { id };

        await this.$di.api.MultiLevelTest.TestsDelete({ data });

        this.resetTestEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetTestEdit() {
      this.confirmDialog = false;
      this.test = {};
    },

    handleCancel() {
      this.resetTestEdit();
    },

    deleteTest(item) {
      this.confirmDialog = true;
      this.test = { ...item };
    },
  },
};
</script>
