<template>
  <div>
    {{ skill.name }}
  </div>
</template>

<script>
export default {
  name: 'TestTableSkillName',
  props: {
    skillId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      skill: {},
    };
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const data = { id: this.skillId };
        const { skill } = await this.$di.api.CompetencyMatrix.SkillsGet(data);
        this.skill = skill;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
  },
};
</script>
