<template>
  <TTView>
    <VRow>
      <VCol>
        <TestsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TestsTableContainer from '../../../components/multi-level-test/tests/TestsTableContainer.vue';

export default {
  name: 'MultiLevelTests',

  components: {
    TestsTableContainer,
  },
};
</script>
